// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const ENVapiKey = process.env.REACT_APP_FIREBASE_KEY;

const firebaseConfig = {
  apiKey: ENVapiKey,
  authDomain: "dare-to-drink.firebaseapp.com",
  projectId: "dare-to-drink",
  storageBucket: "dare-to-drink.appspot.com",
  messagingSenderId: "219099796091",
  appId: "1:219099796091:web:911d8d060d2e48824408de",
  measurementId: "G-9YDD8N1LZP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export {analytics, db};