import React, { useState, useEffect } from 'react';
import Questions from './components/Selection/Questions';
import DareToDrink from './components/Intro/DareToDrink';
import './App.css';

function App() {

  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 4000); 

    return () => clearTimeout(timer); 
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {showIntro ? 
        <div id="dare-to-drink"><DareToDrink/></div>
        :
        <div id="questions"><Questions/></div>
      }
      </header>
    </div>
  );
}

export default App;
