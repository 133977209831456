import React, { useEffect, useState } from 'react';
import './Truth.css';
import { collection, query, where, getDocs, addDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../../firebase-config';

const Truth = ({truthObject}) => {
  const [stage, setStage] = useState(1);
  const [truth, setTruth] = useState(truthObject);

  const handleFeedback = async (feedback) => {
    setStage(3);

    const ref = collection(db, 'Truths'); // Reference to the collection
    const q = query(ref, where('content', '==', truthObject.Content)); // Create a query with a 'where' clause
    const querySnapshot = await getDocs(q); // Execute the query to get matching documents
    console.log(truthObject);
    if (querySnapshot.empty) {
      await addDoc(ref, {
        id: truthObject.Id, 
        type: truthObject.Type,
        location: truthObject.Location,
        content: truthObject.Content,
        Likes: feedback === 'thumbs-up' ? 1 : 0,
        Dislikes: feedback === 'thumbs-up' ?  0 : 1,
      });
    } else {
      const documentRef = querySnapshot.docs[0].ref;
      const fieldToUpdate = feedback === 'thumbs-up' ? 'Likes' : 'Dislikes';
      await updateDoc(documentRef, {
        [fieldToUpdate]: increment(1),
      });
    }
  };

  useEffect(() => {
    let timeoutId;

    if (stage === 1) {
      timeoutId = setTimeout(() => {
        setStage(2);
      }, 2000); 
    } else if (stage === 2) {
    } else if (stage === 3) {
      timeoutId = setTimeout(() => setStage(4), 4000); // Fade-out delay
    } else if (stage === 4) {
      window.location.reload(); // Reload the window after fading out
    }
    return () => clearTimeout(timeoutId);
  }, [stage]);

  return (
    <div className="truth-container">
      {stage === 1 && <div className="fade-in">TRUTH</div>}
      {stage === 2 && (
        <div>
          <div className="display-text">{truth.Content}</div>
          <div className="feedback-buttons">
            <p className="feedback-text">Content Feedback:</p>
            <button className="thumbs-up" onClick={() => handleFeedback('thumbs-up')}>
              👍
            </button>
            <button className="thumbs-down" onClick={() => handleFeedback('thumbs-down')}>
              👎
            </button>
          </div>
        </div>
      )}
      {stage === 3 && <div className="fade-out">TRUTH</div>}
    </div>
  );
};

export default Truth;
