import React, { useEffect, useState } from 'react';
import './Dare.css'; // Make sure to create Dare.css
import { collection, query, where, getDocs, addDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../../firebase-config';

const Dare = ({dareObject}) => {
  const [stage, setStage] = useState(1);
  const [dare, setDare] = useState(dareObject)

  const handleFeedback = async (feedback) => {
    setStage(3);

    const ref = collection(db, 'Dares'); // Reference to the collection
    const q = query(ref, where('content', '==', dareObject.Content)); // Create a query with a 'where' clause
    const querySnapshot = await getDocs(q); // Execute the query to get matching documents
    if (querySnapshot.empty) {
      await addDoc(ref, {
        id: dareObject.Id, 
        type: dareObject.Type,
        location: dareObject.Location,
        content: dareObject.Content,
        Likes: feedback === 'thumbs-up' ? 1 : 0,
        Dislikes: feedback === 'thumbs-up' ?  0 : 1,
      });
    } else {
      const documentRef = querySnapshot.docs[0].ref;
      const fieldToUpdate = feedback === 'thumbs-up' ? 'Likes' : 'Dislikes';
      await updateDoc(documentRef, {
        [fieldToUpdate]: increment(1),
      });
    }
  };

  useEffect(() => {
    let timeoutId;
    if (stage === 1) {
      timeoutId = setTimeout(() => setStage(2), 2000); 
    } else if (stage === 2) {
    } else if (stage === 3) {
      timeoutId = setTimeout(() => setStage(4), 4000); 
    } else if (stage === 4) {
      window.location.reload();
    }

    return () => clearTimeout(timeoutId);
  }, [stage]);

  return (
    <div className="dare-container">
      {stage === 1 && <div className="fade-in">DARE</div>}
      {stage === 2 && (
        <div>
          <div className="display-text">{dare.Content}</div>
          <div className="feedback-buttons">
            <p className="feedback-text">Content Feedback:</p>
            <button className="thumbs-up" onClick={() => handleFeedback('thumbs-up')}>
              👍
            </button>
            <button className="thumbs-down" onClick={() => handleFeedback('thumbs-down')}>
              👎
            </button>
          </div>
        </div>
      )}      
      {stage === 3 && <div className="fade-out">DARE</div>}
    </div>
  );
}

export default Dare;
