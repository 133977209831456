import React, { useState, useEffect } from 'react';
import './Questions.css'; // Make sure the CSS file is updated
import TruthOrDare from './TruthOrDare';
import { analytics } from '../../firebase-config';
import { logEvent } from 'firebase/analytics';

const Questions = () => {
  const [ageConfirmed, setAgeConfirmed] = useState(null);
  const [location, setLocation] = useState('');
  const [choice, setChoice] = useState('');
  const [displayContent, setDisplayContent] = useState('');
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const handleAgeConfirmation = (isOldEnough) => {
    const decision = isOldEnough ? '21+' : 'Minor';
    logEvent(analytics, `user_age_${decision}`);
    setAgeConfirmed(isOldEnough);
  };

  const handleLocationChoice = (locationChoice) => {
    logEvent(analytics, `navigation_click_${locationChoice} `); 
    setLocation(locationChoice);
  };

  return (
    <div className="container">
      {ageConfirmed === null && animate && (
        <div className={`question-area ${animate ? 'fade-in' : ''}`} style={{ marginTop: '30vh' }}>
          <div className="question-text">
            <p>Are you at least 21 years old?</p>
          </div>
          <div className="answers-container">
          <div className="answer-box" style={{ marginRight: '20px' }}>
              <button className="button"  onClick={() => handleAgeConfirmation(true)}>Yes</button>
            </div>
            <div className="answer-box">
              <button className="button" onClick={() => handleAgeConfirmation(false)}>No</button>
            </div>
          </div>
        </div>
      )}
 
      {ageConfirmed === false && <p className="ineligibility-notice">Sorry, you are not eligible to participate.</p>}

      {ageConfirmed && !location && (
        <div className={`question-area fade-in `} style={{ marginTop: '30vh' }}>
          <div className="question-text">
            <p>Are you out on the town or chilling at home?</p>
          </div>
          <div className="answers-container">
            <div className="answer-box" style={{ marginRight: '20px' }}>
              <button className="button" onClick={() => handleLocationChoice('Away')}>Out on the Town</button>
            </div>
            <div className="answer-box">
              <button className="button" onClick={() => handleLocationChoice('AtHome')}>Chilling at Home</button>
            </div>
          </div>
        </div>
      )}

      {location && !choice && (
        <div>
          <TruthOrDare />
        </div>
      )}
    </div>
  );
};

export default Questions;
