import React, { useState, useEffect } from 'react';
import './DareToDrink.css'; // Make sure you update the CSS file with the new styles

const TypewriterText = ({ text, speed }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      setTimeout(() => setIndex(prevIndex => prevIndex + 1), speed);
    }
  }, [index, text, speed]);

  return <span>{text.substring(0, index)}</span>;
};

const DareToDrink = () => {
  return (
    <div className="intro-container">
      <h1 className="dare">DARE.</h1>
      <h2 className="toDrink"><TypewriterText text="to Drink" speed={100} /></h2>
    </div>
  );
};

export default DareToDrink;
