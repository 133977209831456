import './TruthOrDare.css';
import Truth from './Truth';
import Dare from './Dare';
import { analytics } from '../../firebase-config';
import { logEvent } from 'firebase/analytics';
import csvFile from '../../data/datacsv.csv';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';

const TruthOrDare = (props) => {
  const [choice, setChoice] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [content, setContent] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(csvFile) // Fetch the file from the src directory
      .then((response) => response.text())
      .then((csvText) => {
        const parsedData = Papa.parse(csvText, {
          header: true, // Assumes the first row contains headers
        });
        setData(parsedData.data); // Store the parsed data in state
      });
    console.log(data);
  }, []);

  
  // Function to get a random truth or dare based on type and location
  const getRandomTruthOrDare = (data, type, location) => {
    const filteredData = data.filter((item) => {
      const isTypeMatch = item.Type.toLowerCase() === type.toLowerCase();
      const isLocationMatch = item.Location.toLowerCase() === 'both' || item.Location.toLowerCase() === location.toLowerCase();
      return isTypeMatch && isLocationMatch;
    });
  
    const randomIndex = Math.floor(Math.random() * filteredData.length);
    console.log(filteredData);
    console.log(randomIndex);
    return filteredData[randomIndex];
  };
  
  const handleChoice = (selectedChoice) => {
    setShowContent(false); 
    setTimeout(() => {
    setChoice(selectedChoice);
    const truthOrDareData = data; 
    console.log(truthOrDareData);
    const randomTruthOrDare = getRandomTruthOrDare(truthOrDareData, selectedChoice, 'At-Home'); 
    setContent(randomTruthOrDare)
    console.log(`Random ${selectedChoice}: ${randomTruthOrDare.Content}`); 
    logEvent(analytics, `User_Select_${selectedChoice}`); 
    setShowContent(true); 
  }, 1000); 
  };

  return (
    <div className={`truth-or-dare-container ${choice}`}>
      <div className="button-half button-truth" onClick={() => handleChoice('truth')}>
        TRUTH
      </div>
      <div className={`content-overlay ${showContent ? 'show' : ''}`}>
        {choice === 'truth' && <Truth truthObject={content}/>}
        {choice === 'dare' && <Dare dareObject={content}/>}
      </div>
      <div className="button-half button-dare" onClick={() => handleChoice('dare')}>
        DARE
      </div>
    </div>
  );
}

export default TruthOrDare;
